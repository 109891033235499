/* Reset styles */
/* .landingPage {
test {
  color: white;
} */
  
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-smoothing: antialiased; */
  text-rendering: optimizeLegibility;
}

/* HTML font size */
html {
  font-size: calc(0.625rem + 0.41666666666666663vw);
}

@media screen and (max-width: 1920px) {
  html {
      font-size: calc(0.625rem + 0.41666666666666674vw);
  }
}

@media screen and (max-width: 1440px) {
  html {
      font-size: calc(0.8126951092611863rem + 0.20811654526534862vw);
  }
}

@media screen and (max-width: 479px) {
  html {
      font-size: calc(0.7494769874476988rem + 0.8368200836820083vw);
  }
}

.page-wrapper {
  min-height: 100vh; /* Ensure the wrapper takes up the full viewport height */
  display: flex; /* Use flexbox for centering content vertically */
  flex-direction: column; /* Stack child elements vertically */
  overflow-x: hidden; /* Prevent horizontal overflow (as mentioned earlier) */
}

/* Glowing button styles */
.glowing-wrapper-button:hover:after {
  opacity: .7;
}

.glowing-wrapper-active .glowing-wrapper-animations,
.glowing-wrapper-active .glowing-wrapper-borders-masker {
  opacity: 1;
}

.glowing-wrapper-animations:before,
.glowing-wrapper-borders:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.glowing-wrapper-animations:after,
.glowing-wrapper-borders:after {
  clear: both;
  content: "";
  display: block;
}

.glowing-wrapper-animations {
  pointer-events: none;
}

.glowing-wrapper-animations * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.glowing-wrapper-borders,
.glowing-wrapper-glow,
.glowing-wrapper-mask {
  animation: borderTurn 2.5s infinite linear;
  background-image: conic-gradient(from 0 at 50% 50%, rgb(106 196 137) 0deg, rgba(255, 255, 255, 0) 60deg, rgba(255, 255, 255, 0) 310deg, rgb(106 196 137) 360deg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.glowing-wrapper-mask-wrapper {
  -webkit-mask: url("data:image/svg+xml,url('data:image/svg+xml,%253Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%253E%253Crect width='28' height='24' fill='black'/%253E%253C/svg%253E%250A');");
  mask: url("data:image/svg+xml,url('data:image/svg+xml,%253Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%253E%253Crect width='28' height='24' fill='black'/%253E%253C/svg%253E%250A');");
  mask-repeat: repeat;
  -webkit-mask-size: auto;
  mask-size: auto;
}

.glowing-wrapper-borders {
  animation-name: borderTurnWithTranslate;
}

.glowing-wrapper-borders-masker {
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

@keyframes borderTurn {
  0% {
      transform: rotate(0);
  }
  to {
      transform: rotate(360deg);
  }
}

@keyframes borderTurnWithTranslate {
  0% {
      transform: translate(-50%, -50%) rotate(0);
  }
  to {
      transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Global styles */
.inherit-color * {
  color: inherit;
}

*[tabindex]:focus-visible,
input[type="file"]:focus-visible {
  outline: 0.125rem solid #4d65ff;
  outline-offset: 0.125rem;
}

.w-richtext > :not(div):first-child,
.w-richtext > div:first-child > :first-child {
  margin-top: 0 !important;
}

.w-richtext>:last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
  margin-bottom: 0 !important;
}

.pointer-events-off {
  pointer-events: none;
}

.pointer-events-on {
  pointer-events: auto;
}

.div-square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.container-medium,
.container-small,
.container-large {
  margin-right: auto !important;
  margin-left: auto !important;
}

/* Uncomment the following section to enable inherited typography styles */
/*
a,
.w-input,
.w-select,
.w-tab-link,
.w-nav-link,
.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}
*/

.text-style-3lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-style-2lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.display-inlineflex {
  display: inline-flex;
}

.hide {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .hide,
  .hide-tablet {
      display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-mobile-landscape {
      display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .hide-mobile {
      display: none !important;
  }
}

.margin-0 {
  margin: 0rem !important;
}

.padding-0 {
  padding: 0rem !important;
}

.spacing-clean {
  padding: 0rem !important;
  margin: 0rem !important;
}

.margin-top {
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
}

.padding-top {
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}
/* } */